import type { Station, StationListItem } from '~/types/stations'
import type { StationsSearchResponse } from '~/server/api/stations/index.types'

export function useStation(stationId: string) {
  const nuxtApp = useNuxtApp()

  return useAsyncData(`station-${stationId}`, (): Promise<Station> => $fetch(`/api/stations/${stationId}`), {
    getCachedData(key) {
      return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
    },
  })
}

export function useStations(params: object) {
  return useFetch<StationsSearchResponse>('/api/stations/', params)
}

export function useRelatedStations(station: Station) {
  const nuxtApp = useNuxtApp()

  return useAsyncData<StationListItem[]>(
    `station-related-${station.id}`,
    async () => {
      const [genreCountryStations, countryStations] = await Promise.all([
        $fetch<StationsSearchResponse>('/api/stations/', {
          headers: useRequestHeaders(['x-forwarded-for', 'user-agent']),
          params: {
            country: station.country,
            genre: station.genre,
            limit: 13,
          },
        }),
        $fetch<StationsSearchResponse>('/api/stations/', {
          headers: useRequestHeaders(['x-forwarded-for', 'user-agent']),
          params: {
            genre: station.genre,
            limit: 13,
          },
        }),
      ])

      return genreCountryStations.data
        .concat(countryStations.data)
        .filter((item) => item.id !== station.id)
        .slice(0, 12)
    },
    {
      getCachedData(key) {
        return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
      },
    }
  )
}

export function useGetTrendingStation() {
  const nuxtApp = useNuxtApp()

  return useAsyncData(`station-trending`, (): Promise<StationListItem[]> => $fetch(`/api/stations/trending/`), {
    getCachedData(key) {
      return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
    },
  })
}
